<template>
  <div class="emissions-report">
    <ReportPreview
      v-if="$store.state.app.clientDataLoaded && this.reportDataLoaded && this.$store.state.app.report"
      :pages="pages"
      :overflowData="overflowData"
      :methodologyDiagram="methodologyDiagram"
      :emissionsByScope="emissionsByScope"
      :emissionSources="emissionSources"
      :carbonPrice="carbonPrice"
    />
    <div v-else class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

  </div>
</template>

<script>
import ReportPreview from './ReportPreview.vue'
import emissionsMixin from '../../mixins/emissionsMixin.vue'
import axios from '@/axios'
import _ from 'lodash'

export default {
  mixins: [emissionsMixin],
  props: { period: String, emissionsByScope: Object, emissionSources: Array, carbonPrice: Number },
  components: {
    ReportPreview
  },
  data () {
    return {
      reportDataLoaded: false,
      pages: [],
      originalPages: [],
      methodologyDiagram: '',
      overflowData: {}
    }
  },
  async beforeMount () {
    await this.setReportTemplate()
    this.reportDataLoaded = true
  },
  methods: {
    async setReportTemplate () {
      const response = await this.getReportTemplate()
      this.pages = response.pages
      this.generateOverflowContent()
      this.adjustPages()

      this.methodologyDiagram = response.methodologyDiagram
    },
    getReportTemplate () {
      var config = {
        method: 'get',
        url: `${process.env.VUE_APP_APIURL}report/generateReportSchema/EIRTemplate`,
        headers: {}
      }

      return axios(config)
        .then(function (response) {
          // console.log(response, 'generate report schema')
          return response.data
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    removeEmptyPages (pages) {
      let newPages = []
      pages.forEach(page => {
        if (this.checkPage(page)) {
          newPages.push(page)
        }
      })
      return newPages
    },
    checkPage (page) {
      if (page.title === 'Excluded Emissions Sources' && this.$store.state.app.report.excludedEmissionsSources.nodes.length === 0) {
        return false
      }
      return true
    },
    async handleSettingsChanged () {
      this.overflowData = {}
      this.reportDataLoaded = false
      await this.setReportTemplate()
      this.reportDataLoaded = true
    },
    adjustPages () {
      this.pages = this.checkMultipleBusinessUnits(this.pages)
      this.pages = this.checkTableOverflows(this.pages)
      this.pages = this.removeEmptyPages(this.pages)
    },
    generateOverflowContent () {
      if (this.settings.emissionsByActivities.split) {
        this.getEmissionsByActivities(this.settings.emissionsByActivities)
      }
      if (this.settings.activityDataCollection.split) {
        this.getActivityDataCollection(this.settings.activityDataCollection)
      }
      if (this.settings.businessUnits.split) {
        this.getBusinessUnits(this.settings.businessUnits)
      }
    },
    checkMultipleBusinessUnits (pages) {
      let newPages = []
      pages.forEach(page => {
        // check if page has heading with title 'Business Units Breakdown'
        if (page.headings.length && page.headings[0].title && page.headings[0].title === 'Business Units Breakdown') {
          // if not multiple business units, don't add page
          if (this.multipleBusinessUnits()) {
            newPages.push(page)
          }
        } else {
          newPages.push(page)
        }
      })
      return newPages
    },
    multipleBusinessUnits () {
      // check for more than one business unit with emissions
      let count = 0
      this.$store.state.app.client.allBusinessUnits.nodes.forEach(businessUnit => {
        let hasEmissions = false
        businessUnit.periods.nodes.forEach(period => {
          if (period.emissionsSources.nodes.length > 0) {
            hasEmissions = true
          }
        })
        if (hasEmissions) {
          count++
        }
      })
      return count > 1
    },
    checkTableOverflows (pages) {
      // console.log('checking table overflows')
      if (this.settings.emissionsByActivities.split) {
        pages = this.checkEmissionsByActivityTableOverflows(pages, this.settings.emissionsByActivities)
      }
      if (this.settings.activityDataCollection.split) {
        pages = this.checkActivityDataCollectionTableOverflows(pages, this.settings.activityDataCollection)
      }
      if (this.settings.businessUnits.split) {
        pages = this.checkBusinessUnitsTableOverflows(pages, this.settings.businessUnits)
      }

      return pages
    },
    checkEmissionsByActivityTableOverflows (pages, settings) {
      let emissionsByActivitiesSplitCount = 1 + Math.ceil((this.emissionSources.length - settings.firstSplitCount) / settings.splitCount)
      pages.slice(0).forEach((page, index) => {
        // check table 7 emissionsByActivities
        if (page.content && page.content.includes('[emissionsByActivities]')) {
          // splice page and insert overflow data
          for (let i = 0; i < emissionsByActivitiesSplitCount; i++) {
            if (i > 0) {
              let newPage = {
                content: `[emissionsByActivities${i}]`,
                title: null,
                headings: []
              }
              pages.splice(index + i, 0, newPage)
              // pages.push(newPage)
            }
          }
        }
      })
      return pages
    },
    checkActivityDataCollectionTableOverflows (pages, settings) {
      pages.slice(0).forEach((page, index) => {
        let allSchemas = []
        this.$store.state.app.client.allBusinessUnits.nodes.forEach(businessUnit => {
          businessUnit.periods.nodes.forEach(period => {
            const periodSchemas = this.getAllActiveSchemas(JSON.parse(period.masterSchema))
            allSchemas = allSchemas.concat(periodSchemas)
          })
        })
        let activityDataCollectionSplitCount = 1 + Math.ceil((allSchemas.length - settings.firstSplitCount) / settings.splitCount)
        if (page.headings.length && page.headings[0].content && page.headings[0].content.includes('[activityDataCollection]')) {
          // get finalContent
          let exisitingContent = page.headings[0].content.split('[activityDataCollection]</p>')
          // splice page and insert overflow data
          for (let i = 0; i < activityDataCollectionSplitCount; i++) {
            // console.log(activityDataCollectionSplitCount, 'split count')
            if (i === 0 && i !== activityDataCollectionSplitCount - 1) {
              // if first page and not last page
              // console.log('first page')
              // replace original page content
              page.headings[0].content = exisitingContent[0] + '<p>[activityDataCollection]</p>'
            } else if (i > 0 && i !== activityDataCollectionSplitCount - 1) {
              // if middle page
              let newPage = {
                content: `[activityDataCollection${i}]`,
                title: null,
                headings: []
              }
              // add page
              pages.splice(index + i, 0, newPage)
            } else if (i > 0 && i === activityDataCollectionSplitCount - 1) {
              // console.log('last page')
              // if last page
              let newPage = {
                content: `[activityDataCollection${i}]${exisitingContent[1]}`,
                title: null,
                headings: []
              }
              // add page
              pages.splice(index + i, 0, newPage)
            }
          }
        }
      })
      return pages
    },
    checkBusinessUnitsTableOverflows (pages, settings) {
      let businessUnitsSplitCount = 1 + Math.ceil((this.$store.state.app.report.legalEntities.nodes.length - settings.firstSplitCount) / settings.splitCount)
      pages.slice(0).forEach((page, index) => {
        if (page.headings.length && page.headings[0].content && page.headings[0].content.includes('[businessUnits]')) {
          // get finalContent
          let exisitingContent = page.headings[0].content.split('[businessUnits]</p>')
          // splice page and insert overflow data
          for (let i = 0; i < businessUnitsSplitCount; i++) {
            // console.log(businessUnitsSplitCount, 'split count')
            if (i === 0 && i !== businessUnitsSplitCount - 1) {
              // if first page and not last page
              // console.log('first page')
              // replace original page content
              page.headings[0].content = exisitingContent[0] + '<p>[businessUnits]</p>'
            } else if (i > 0 && i !== businessUnitsSplitCount - 1) {
              // if middle page
              let newPage = {
                content: `[businessUnits${i}]`,
                title: null
              }
              // add page
              pages.splice(index + i, 0, newPage)
            } else if (i > 0 && i === businessUnitsSplitCount - 1) {
              // console.log('last page')
              // if last page
              let newPage = {
                content: `[businessUnits${i}]${exisitingContent[1]}`,
                headings: []
              }
              // add page
              pages.splice(index + i, 0, newPage)
            }
          }
        }
      })
      return pages
    },
    getEmissionsByActivities (settings) {
      // table 5
      let cellCount = 3
      let headingData = ['Emission Sources', 'GHG tCO<sub>2</sub>e', '% of Inventory']
      let heading = this.renderCells(headingData, cellCount, true)
      let splitCount = settings.firstSplitCount
      let mainData = []
      var count = 0
      let numberSplit = 0
      this.emissionSources.forEach((es, index) => {
        mainData.push(es['Emissions source'])
        // market or location based
        mainData.push(this.marketRateExists && es['tCO2e (Market Rate)'] !== 'N/A' ? es['tCO2e (Market Rate)'] : es['tCO2e (Output)'])
        mainData.push(this.marketRateExists && es.percentageOfInventoryMR !== 'N/A' ? es.percentageOfInventoryMR : es.percentageOfInventory)
        count++
        if (numberSplit > 0) {
          splitCount = settings.splitCount
        }
        if (count === splitCount || index === this.emissionSources.length - 1) {
          count = 0
          let main = this.renderCells(mainData, cellCount, false)
          let totalRowData = [
            'Grand Total',
            this.marketRateExists && this.emissionsByScope.totalEmissionsMarketRate ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions,
            '100.00%'
          ]
          let total = this.renderCells(totalRowData, cellCount, true)
          let property = numberSplit === 0 ? 'emissionsByActivities' : `emissionsByActivities${numberSplit}`
          let table = index === this.emissionSources.length - 1 ? `<table>${heading}${main}${total}</table>` : `<table>${heading}${main}</table>`
          this.overflowData[property] = table
          numberSplit++
          // empty mainData
          mainData = []
        }
      })
    },
    // 7 Data Quality, Uncertainties and Assumptions
    getActivityDataCollection (settings) {
      let cellCount = 7
      let headingData = [
        'Business unit',
        'Emissions source',
        'Scope',
        'Unit',
        'Data source',
        'Data quality',
        'Any assumptions made'
      ]
      let count = 0
      let numberSplit = 0
      let splitCount = settings.firstSplitCount
      let activityData = []

      // Create a flat array of all schemas across all business units and periods
      let allSchemas = []

      this.$store.state.app.client.allBusinessUnits.nodes.forEach(businessUnit => {
        businessUnit.periods.nodes.forEach(period => {
          const periodSchemas = this.getAllActiveSchemas(JSON.parse(period.masterSchema))

          // Add businessUnit title to each schema
          periodSchemas.forEach(schema => {
            schema.businessUnit = businessUnit.title
          })

          allSchemas = allSchemas.concat(periodSchemas)
        })
      })

      console.log(allSchemas)

      // Process all schemas with pagination
      allSchemas.forEach((schema, index) => {
        if (schema.name !== 'Additional Sources') {
          activityData.push(schema.businessUnit)
          activityData.push(schema.title)
          activityData.push(schema.scope.toString())
          activityData.push(
            schema.name === 'Contracted Services and Providers'
              ? 'tCO2e'
              : schema.emissionSources[0].unit
          )
          activityData.push(schema.dataSource)
          activityData.push(schema.dataQuality)
          activityData.push(schema.assumptions)
        } else {
          schema.emissionSources.forEach(source => {
            if (source.annualTotal === 0) return
            activityData.push(schema.businessUnit)
            activityData.push(source.name)
            activityData.push(source.GHGScope.toString())
            activityData.push(source.unit)
            activityData.push(source.emissionSourceInfo.dataSource)
            activityData.push(source.emissionSourceInfo.dataQuality)
            activityData.push(source.emissionSourceInfo.assumptions)
          })
        }

        let heading = this.renderCells(headingData, cellCount, true)
        let activityDataTable = this.renderCells(activityData, cellCount, false)

        count++
        if (numberSplit > 0) {
          splitCount = settings.splitCount
        }

        // Check if we need to create a new page
        if (count === splitCount || index === allSchemas.length - 1) {
          count = 0
          let property = numberSplit === 0 ? 'activityDataCollection' : `activityDataCollection${numberSplit}`
          let table = `<table class="data-quality">${heading}${activityDataTable}</table>`
          this.overflowData[property] = table
          numberSplit++
          activityData = []
        }
      })
    },
    getBusinessUnits (settings) {
      let count = 0
      let numberSplit = 0
      let splitCount = settings.firstSplitCount
      // add table headings
      let tableHeadings =
        '<tr><th>Legal entities (Include any subsidaries)</th><th>Business unit / Location</th><th>Activities / Purpose</th><th>Included / Excluded</th><th>Reason for exclusion</th></tr>'
      let tableData = ''
      // loop through entities and add to table row
      this.$store.state.app.report.legalEntities.nodes.forEach((entity, index) => {
        tableData += '<tr>'
        tableData += `<td>${entity.title ? entity.title : ''}</td>`
        tableData += `<td>${entity.location ? entity.location : ''}</td>`
        tableData += `<td>${
          entity.purpose ? entity.purpose : ''
        }</td>`
        tableData += `<td>${entity.status}</td>`
        tableData += `<td>${
          entity.reasonForExclusion ? entity.reasonForExclusion : ''
        }</td>`
        tableData += '</tr>'
        count++
        if (numberSplit > 0) {
          splitCount = settings.splitCount
        }
        if (count === splitCount || index === this.$store.state.app.report.legalEntities.nodes.length - 1) {
          count = 0
          // add table to overflowData
          let property = numberSplit === 0 ? 'businessUnits' : `businessUnits${numberSplit}`
          let table = `<table>${tableHeadings}${tableData}</table>`
          this.overflowData[property] = table
          numberSplit++
          // empty tableData
          tableData = ''
        }
      })
      // piece table together
    },
    renderCells (cells, cellCount = 0, heading) {
      let cellData = ''
      if (cellCount > 0) {
        // generate number of cells based on cellCount per row
        var count = 1
        cells.forEach((cell, index) => {
          if (count === 1) {
            cellData += `<tr ${heading ? "class='heading'" : ''}>`
          }
          cell = cell === Infinity || cell === 0 ? '0.00' : cell
          cellData += `<td>${
            cell !== null
              ? isNaN(cell) || cell === '' || typeof cell === 'string'
                ? cell
                : this.$root.formatNumber(cell)
              : cell !== undefined
                ? ''
                : '0.00'
          }</td>`
          if (count === cellCount) {
            cellData += '</tr>'
            count = 1
          } else {
            count++
          }
        })
      } else {
        // basic two column row generation
        cells.forEach(cell => {
          cellData += '<tr>'
          cellData += `<td>${cell.title}</td>`
          cellData += `<td>${typeof cell.value === 'string' ? cell.value : this.$root.formatNumber(cell.value)}</td>`
          cellData += '</tr>'
        })
      }
      return cellData
    }
  },
  computed: {
    marketRateExists () {
      return this.checkEmissionsSourcesForMarketRate(this.$root.allEmissionSources)
    },
    settings () {
      return JSON.parse(JSON.stringify(this.$store.state.app.report.settings))
    }

  },
  watch: {
    settings: {
      deep: true,
      handler (newVal, oldVal) {
        const areEqual = _.isEqual(newVal, oldVal)
        if (!areEqual) {
          this.handleSettingsChanged()
        }
      }
    }
  }
}
</script>
