import gql from 'graphql-tag'

export default {
  query: gql`
    query (
      $filter: ClientFilterFields!
      $previousYearFilter: PeriodFilterFields!
      $reportFilter: ReportFilterFields!
      $generalMetricsFilter: EmissionIntensityMetricFilterFields!
      $otherMetricsFilter: EmissionIntensityMetricFilterFields!
      $completedInventoriesFilter: PeriodFilterFields!
      $activeClientPeriodFilter: PeriodFilterFields!
      $activeOrganisationPeriodFilter: PeriodFilterFields!
      $businessUnitsFilter: OrganisationFilterFields!
      $allBusinessUnitsFilter: OrganisationFilterFields!
      $activePeriodFilter: PeriodFilterFields!
    ) {
      readOneClient(filter: $filter) {
        id
        title
        businessSector
        websiteAddress
        description
        statementOfIntent
        intendedUse
        organisationalChartThumbURL
        organisationalChartURL
        tradingName
        preferredName
        reportingMonthStart
        baseYearReportingMonthStart
        baseYearDescription
        baseYearID
        baseYear {
          id
          status
          year
          lastUpdated
          emissionsSources {
            nodes {
              ID
              ESID
              name
              type
              rule
              tCO2e
              tCO2eMarketRate
              CO2
              CH4
              N2O
              PFC
              HFC
              SF6
              annualTotal
              unit
              category
              subcategory
              GHGScope
              GHGSubcategory
              ISOCategory
            }
          }
        }
        previousYear: periods(filter: $previousYearFilter) {
          nodes {
            id
            status
            year
            lastUpdated
            emissionsSources {
              nodes {
                ID
                ESID
                name
                type
                rule
                tCO2e
                tCO2eMarketRate
                CO2
                CH4
                N2O
                PFC
                HFC
                SF6
                annualTotal
                unit
                category
                subcategory
                GHGScope
                GHGSubcategory
                ISOCategory
              }
            }
          }
        }
        completedInventories: periods(filter: $completedInventoriesFilter) {
          nodes {
            id
            year
          }
        }
        allBusinessUnits: businessUnits(filter: $allBusinessUnitsFilter) {
          nodes {
            id
            title
            periods(filter: $activePeriodFilter) {
              nodes {
                id
                year
                status
                month
                masterSchema
                emissionsSources {
                  nodes {
                    ID
                    ESID
                    name
                    type
                    rule
                    tCO2e
                    tCO2eMarketRate
                    CO2
                    CH4
                    N2O
                    PFC
                    HFC
                    SF6
                    annualTotal
                    unit
                    category
                    subcategory
                    GHGScope
                    GHGSubcategory
                    ISOCategory
                  }
                }
              }
            }
          }
        }
        businessUnits(filter: $businessUnitsFilter) {
          nodes {
            id
            title
            tradingName
            preferredName
            reportingMonthStart
            baseYearReportingMonthStart
            baseYearDescription
            baseYearID
            period: periods(filter: $activeOrganisationPeriodFilter) {
              nodes {
                id
                year
                masterSchema
                emissionsSources {
                  nodes {
                    ID
                    ESID
                    name
                    type
                    rule
                    tCO2e
                    tCO2eMarketRate
                    CO2
                    CH4
                    N2O
                    PFC
                    HFC
                    SF6
                    annualTotal
                    unit
                    category
                    subcategory
                    GHGScope
                    GHGSubcategory
                    ISOCategory
                  }
                }
              }
            }
            activities {
              nodes {
                id
                title
                version
                identifier
              }
            }
          }
        }
        period: periods(filter: $activeClientPeriodFilter) {
          nodes {
            id
            year
            status
            month
            emissionsSources {
              nodes {
                ID
                ESID
                name
                type
                rule
                tCO2e
                tCO2eMarketRate
                CO2
                CH4
                N2O
                PFC
                HFC
                SF6
                annualTotal
                unit
                category
                subcategory
                GHGScope
                GHGSubcategory
                ISOCategory
              }
            }
          }
        }
        contactPerson {
          id
          firstName
          surname
          email
          contactNumber
        }
      }
      readOneReport(filter: $reportFilter) {
        id
        year
        period
        preparedBy
        reviewedBy
        status
        assuranceType
        customCreditOffsetAmount
        offsetExplanation
        consolidationApproach
        consolidationReason
        emissionReductionNotes
        doubleCountingPreOffsetExtra
        initialCarbonOffset
        notes
        GHGRemoval
        projectDetails
        methodologyChanges
        organisationChartNotes
        clientID
        lastEdited
        recognisedOffsetDeduction
        emissionsAgainstPreviousYearsNotes
        noKnownInstancesOfDoubleCounting
        instancesOfDoubleCountingNotes
        previousElectricityWTTWasMaterial
        settings
        carbonCreditType {
          id
          title
          description
        }
        carbonCreditTypeID
        certificationType {
          id
          title
        }
        certificationTypeID
        emissionIntensityMetricsGeneral: emissionIntensityMetrics(
          filter: $generalMetricsFilter
        ) {
          nodes {
            id
            title
            value
            type
          }
        }
        emissionIntensityMetricsOther: emissionIntensityMetrics(
          filter: $otherMetricsFilter
        ) {
          nodes {
            id
            title
            value
            type
          }
        }
        legalEntities {
          nodes {
            id
            title
            location
            status
            reasonForExclusion
            purpose
          }
        }
        excludedEmissionsSources {
          nodes {
            id
            title
            scope
            reasonForExclusion
          }
        }
        emissionCategoryGroups {
          nodes {
            id
            title
            emissionCategories {
              nodes {
                id
                title
                description
                ekosPosition
                status
                exclusionCriteria
                notes
              }
            }
          }
        }
        otherEmissions {
          nodes {
            id
            title
            identifier
            status
            notes
          }
        }
        cancelledCarbonCredits {
          nodes {
            id
            amount
            customDescription
            carbonCreditTypeID
            carbonCreditType {
              id
              title
              description
            }
          }
        }
        recognisedOffsetDeductions {
          nodes {
            id
            hash
            title
            notes
            amount
            type
          }
        }
      }
      readCertificationTypes {
        nodes {
          id
          title
        }
      }
    }
  `
}
